import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

const UserPassword = ({
  openDialog,
  onCancel,
  onSave,
}) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");
  const validateConfirmPassword = (value) =>
    value === passwordRef.current || 'Passwords do not match';

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openDialog}
      onClose={onCancel}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <form >
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Current Password"
            type="password"
            name="currentPassword"
            {...register("currentPassword", {
              required: true,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{}|;:,.<>?]).{8,}$/
            })}
            error={!!errors.currentPassword}
            helperText={errors.currentPassword && 'Current password is required'}
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            {...register("password", {
              required: true,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{}|;:,.<>?]).{8,}$/
            })}
            error={!!errors.password}
            helperText={errors.password && 'Password is required'}
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            {...register("confirmPassword", {
              required: true,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{}|;:,.<>?]).{8,}$/,
              validate: validateConfirmPassword,
            })}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword && 'Passwords must match'}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserPassword.defaultProps = {

};

UserPassword.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UserPassword;
