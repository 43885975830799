import React, { useMemo, useState, useEffect } from 'react';
import BillAuditDashboard from "./BillAuditDashboard";
import { useLoaderData } from 'react-router-dom';
import { GetQueryParams, GetModifiedBillingCycleList } from '../../../../util/Utility';
import { useAuthContext } from '../../../../store/AuthContext';

import { SingleSelector, SubmitButton } from '../../../UI/UIComponents';

function BillAuditContent() {
    const { clientConfig } = useAuthContext();

    const [supplier, setSupplier] = useState('');
    const [billingCycle, setBillingCycle] = useState('');
    const [filterParam, setFilterParam] = useState();
    const [loading, setLoading] = useState();
    const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);
    const queryParams = GetQueryParams();

    const { supplierFilterData, billingCycleFilterData } = useLoaderData();
    const supplierList = useMemo(() => supplierFilterData.data.result.Items.map(item => {
        return {
            ...item,
            value: item.supplier_name
        }
    }), [supplierFilterData.data.result.Items]);
    const billingCycleList = billingCycleFilterData.data.result.Items.map(item => {
        return {
            ...item,
            value: item.billing_period
        }
    })

    const constructedBillingCycleList = GetModifiedBillingCycleList(billingCycleList, clientConfig);

    useEffect(() => {
        if(!queryFiredOnLoad) {
            const querySupplier = queryParams.get("supplier");
            const queryBillingCycle = queryParams.get("billingCycle");
            let newSupplier;
            let runQuery = false;

            if (querySupplier != null) {
                const inputSupplier = supplierList.filter((item) => item.supplier_id === querySupplier);
                if (inputSupplier.length === 1) {
                    newSupplier = inputSupplier[0].value;
                    setSupplier(newSupplier);
                    // runQuery = true;
                }
            }

            if (queryBillingCycle != null) {
                setBillingCycle(queryBillingCycle);
                runQuery = true;
            }

            if (runQuery) {
                setQueryFiredOnLoad(true);
                setLoading(true);
                setFilterParam({
                    'supplier': newSupplier ?? supplier,
                    'yearMonth': queryBillingCycle ?? billingCycle,
                });
            }
        }
    }, [billingCycle, queryFiredOnLoad, queryParams, supplier, supplierList]);

    const filterHandler = () => {
        setLoading(true);
        setFilterParam({
            'supplier': supplier,
            'yearMonth': billingCycle,
        });
    }

    return (
        <div className="mainContent">
            <div className=" pl-32 pr-32">
                <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
                    <div className=" customerBillingForm spark-search-tab-card">
                        <form action="" method="post">
                            <div className="row">
                                <SingleSelector
                                    value={supplier}
                                    setValue={setSupplier}
                                    valueList={supplierList}
                                    label="Supplier"
                                />

                                <div className="col-1" />

                                <SingleSelector
                                    value={billingCycle}
                                    setValue={setBillingCycle}
                                    valueList={constructedBillingCycleList}
                                    label="Billing Cycle"
                                />

                                <div className="col-5" />

                                <SubmitButton
                                    filterHandler={filterHandler}
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <BillAuditDashboard filterParam={filterParam} setLoading={setLoading} />
        </div>
    );
}

export default BillAuditContent;