import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export function CalendarSelector({ dateValue, setDateValue, minDate, maxDate, disabled, disableFuture, label, views = ["year", "month", "day"], variant = "calendar", blockClassName = "col-lg-2 col-md-6 mb-lg-0 mb-1" }) {
    return (
        <div className={blockClassName}>
            <label htmlFor="rateClassDate" className="form-label">{label}</label>
            <div className='row me-3'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        variant={variant}
                        value={dateValue}
                        disabled={disabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        views={views}
                        openTo={views[views.length - 1]}
                        disableFuture={disableFuture}
                        slotProps={{ textField: { placeholder: `Select ${label}` } }}
                        onChange={(newValue) => setDateValue(newValue)} />
                </LocalizationProvider>
            </div>
        </div>
    );
}
