import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../../util/APIDataLoader';

function BillShadowingDashboard( { filterParam, setLoading }) {
    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('customerBillShadowing', ConstructFilterParams(filterParam), setLoading, 'billShadowingDashboardContainer');
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="billShadowingDashboardContainer"></div>
    );
}

export default BillShadowingDashboard;
