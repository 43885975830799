import React, { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import {
  Button,
  Alert,
  Slider,
  Container,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useAuthContext } from '../../store/AuthContext';
import UserPassword from './UserPassword';

const cacheItems = [
  'rateClasses',
  'gridLocation',
  'rateCalcDefaut',
  'billAuditYearFilter',
  'supplierCalcDefault',
  'customers',
  'billingSelector',
  'customerSelector',
  'customerSearchSelector',
  'rateSelector',
  'gridLocationSelector',
  'supplierSelector',
];

const PreferencesContent = () => {
  const {
    setGridfinTheme,
    setGridfinThemeDashboard,
    setGridfinCache,
    clientConfig,
    signOut,
  } = useAuthContext();
  const [themeList, setThemeList] = useState(['light', 'dark']);
  const [quickSIghtThemeList, setQuickSightThemeList] = useState([]);
  const [theme, setTheme] = useState('light');
  const [themeDashboard, setThemeDashboard] = useState('');
  const [cacheSelected, setCacheSelected] = useState(true);
  const [cacheTTL, setCacheTTL] = useState(0);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [openUserPasswordDialog, setOpenUserPasswordDialog] = useState(false);

  useEffect(() => {
    const getQuickSightThemeList = async () => {
      const authData = await Auth.currentSession();
      const { idToken: {
        jwtToken,
        payload: {
          sub }
      }
      } = authData;
      const params = {
        headers: {},
        response: true,
        queryStringParameters: {
          jwtToken: jwtToken,
          payloadSub: sub,
        }
      }
      const listResult = await API.get('GridFin', '/common/quicksighthemes', params);
      const data = listResult.data.themes.map((item) => {
        return {
          ...item,
          value: item.Arn,
          label: item.Name,
        };
      });
      data.unshift({ value: 'None', label: 'None' });
      setQuickSightThemeList(data);
    };

    if (clientConfig) {
      setTheme(clientConfig.theme.gridfin.name);
      setThemeList(clientConfig.theme.list);
      setCacheSelected(clientConfig.cache.enabled);
      setCacheTTL(clientConfig.cache.ttl);
      getQuickSightThemeList();
      setThemeDashboard(clientConfig.theme.quickSight.themeArn);
    }
  }, [clientConfig]);

  const handleThemeUpdate = (value) => {
    localStorage.setItem('gridfinTheme', value);
    setGridfinTheme(value);
    setTheme(value);
    setMessage('');
  };

  const handleDashboardThemeUpdate = (value) => {
    localStorage.setItem('gridfinDashboardTheme', value);
    setGridfinThemeDashboard(value);
    setThemeDashboard(value);
    setMessage('');
  };

  const handleCacheSelected = () => {
    const enabled = !cacheSelected;
    setCacheSelected(enabled);
    localStorage.setItem('gridfinCacheEnabled', enabled);
    setGridfinCache({
      enabled,
      ttl: cacheTTL,
    });
  };

  const handleTTLChanged = (value) => {
    setCacheTTL(value)
    localStorage.setItem('gridfinCacheTTL', value);
    setGridfinCache({
      enabled: cacheSelected,
      ttl: value,
    });
  };

  const handleClearCache = () => {
    cacheItems.forEach((key) => {
      localStorage.removeItem(key);
    });
    setMessage('Cache cleared');
    setSeverity('success');
  };

  const buildMessage = () => {
    if (!message || message.length === 0) {
      return (<></>);
    }

    return (
      <Alert severity={severity}>{message}</Alert>
    )
  };

  const handleCloseUserPasswordDialog = () => {
    setOpenUserPasswordDialog(false);
  };

  const handleSaveUserPasswordDialog = async (data) => {
    try {
      const { currentPassword, password } = data;
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, password);
      setMessage('Password changed successfully');
      setSeverity('success');
      signOut();
    } catch (error) {
      console.log(error);
      setMessage(error?.response?.data?.message);
      setSeverity('error');
    }
    setOpenUserPasswordDialog(false);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Preferences
      </Typography>
      {buildMessage()}
      <Box sx={{ marginTop: '20px' }}>
        <InputLabel id="theme-label">Application Theme</InputLabel>
        <Select
          labelId="theme-label"
          id="theme-select"
          value={theme}
          onChange={(e) => handleThemeUpdate(e.target.value)}
        >
          {themeList.map((theme) => {
            return (<MenuItem value={theme} key={theme}>{theme}</MenuItem>);
          })}
        </Select>
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <InputLabel id="theme-label">Dashboard Theme</InputLabel>
        <Select
          labelId="theme-label"
          id="theme-select"
          value={themeDashboard}
          onChange={(e) => handleDashboardThemeUpdate(e.target.value)}
        >
          {quickSIghtThemeList.map((theme) => {
            return (<MenuItem value={theme.value} key={theme.value}>{theme.label}</MenuItem>);
          })}
        </Select>
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <Box display="flex" alignItems="center">
          <Switch checked={cacheSelected}
            onChange={(e) => handleCacheSelected(e.target.value)}
          />
          Enable Cache
          <Tooltip title="Data used for selection of Billing Cycle, Supplier etc. is locally cached.">
            <IconButton size="small" sx={{ marginLeft: 1 }}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ marginTop: '10px', width: '40%' }}>
        <Typography id="cache-slider" gutterBottom>
          Cache Lifespan (1-10) Days
        </Typography>
        <Slider
          value={cacheTTL}
          onChange={(e) => handleTTLChanged(e.target.value)}
          min={1}
          max={10}
          step={1}
          marks
          valueLabelDisplay="auto"
          aria-labelledby="cache-slider"
        />
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <Button variant="outlined" color="primary" onClick={handleClearCache}>
          Clear Cache
        </Button>
        <Tooltip title="Clear cache if you want to force a refresh of data from source.">
          <IconButton size="small" sx={{ marginLeft: 1 }}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <Button variant="outlined" color="primary" onClick={() => setOpenUserPasswordDialog(true)}>
          Change Password
        </Button>
      </Box>
      <UserPassword
        openDialog={openUserPasswordDialog}
        onCancel={handleCloseUserPasswordDialog}
        onSave={handleSaveUserPasswordDialog}
      />
    </Container>
  );
};

export default PreferencesContent;
