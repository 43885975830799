import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../util/APIDataLoader';

function BillAuditDashboard( { filterParam, setLoading }) {
    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('executiveSummary', ConstructFilterParams(filterParam), setLoading, "executiveSummaryDashboardContainer");
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="executiveSummaryDashboardContainer"></div>
    );
}

export default BillAuditDashboard;
