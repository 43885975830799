
import RateAnalysisContent from "../../components/PowerPNL/RateAnalysis/RateAnalysisContent";
import { APIDataLoader } from "../../util/APIDataLoader";

function RateAnalysis() {
    return (
        <RateAnalysisContent />
    )
}

export default RateAnalysis;

export async function loader() {
    const promises = await Promise.all([
        APIDataLoader('rateSelector'),
        APIDataLoader('gridLocationSelector'),
    ]);

    return {
        rateClassesData: promises[0],
        gridLocationFilterData: promises[1],
    };
};
