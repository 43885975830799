import { useRouteError } from "react-router-dom";
import { Box, Toolbar } from '@mui/material';
import PageContent from "../components/PageContent/PageContent";
import Header from "../components/Header/Header";
import SideBarMenu from "../components/SideBarMenu/SideBarMenu";

const drawerWidth = 240;

function ErrorPage() {
    const error = useRouteError();

    let title = 'An error occurred!';
    let message = 'Something went wrong!';

    if (error.status === 500) {
        message = JSON.parse(error.data).message;
    }

    if (error.status === 404) {
        title = 'Not found!';
        message = 'Could not find resource or page.';
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <Header drawerWidth={drawerWidth}></Header>
            <SideBarMenu drawerWidth={drawerWidth}></SideBarMenu>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <Toolbar />
                <PageContent title={title}>
                    <p>{message}</p>
                </PageContent>
            </Box>
        </Box>
    );
}

export default ErrorPage;