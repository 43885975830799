import React from "react";

import AdminContent from '../components/Admin/AdminContent';

const Admin = () => {
  return (
    <AdminContent />
  );
};

export default Admin;

export const loader = async () => {
  return {};
};
