import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import {
  MultiSelector,
} from '../../UI/UIComponents';

const UserForm = ({
  open,
  onCancel,
  onSave,
  user,
  groupList,
  isAdmin,
  clientList,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      email: 'example@example.com',
      password: '',
      confirmPassword: '',
      clientId: '',
      groups: [],
    },
  });

  const [saveLabel, setSaveLabel] = useState('Create');
  const [title, setTitle] = useState('Create User');
  const [allowedGroups, setAllowedGroups] = useState([]);

  useEffect(() => {
    setSaveLabel((user) ? 'Save' : 'Create');
    setTitle((user) ? user.Email : 'Create User');
    const updatedValues = {
      email: '',
      password: '',
      confirmPassword: '',
      clientId: (user) ? user.Client : '',
      groups: (user) ? user.Groups.split(',').map((group) => { return group.trim() }) : [],
    };
    reset(updatedValues);
  }, [user, reset]);

  useEffect(() => {
    if (groupList) {
      let data = [...groupList];
      if (!isAdmin) {
        data = groupList.filter((group) => group.value !== 'Admin');
      }

      setAllowedGroups(data);
    }
  }, [groupList, isAdmin]);

  useEffect(() => {
    if (clientList.length > 0) {
      setValue('clientId', clientList[0].value);
    }
  }, [clientList, setValue]);

  const onSubmit = (data) => {
    onSave(data);
  };

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");
  const validateConfirmPassword = (value) =>
    value === passwordRef.current || 'Passwords do not match';

  const validateGroups = (value) => value.length > 0 || 'Must select at least one group';

  const handleSetGroups = async (newValue) => {
    setValue('groups', newValue);
  };


  const buildEmail = () => {
    if (user !== null) {
      return (<></>);
    }

    return (
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="Email"
        type="email"
        name="email"
        {...register("email", {
          required: true,
          pattern: /^\S+@\S+$/i
        })}
        error={!!errors.email}
        helperText={errors.email && 'Email is required'}
      />
    );
  };

  const buildPassword = () => {
    if (user !== null) {
      return (<></>);
    }

    return (
      <>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Password"
          type="password"
          name="password"
          {...register("password", {
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{}|;:,.<>?]).{8,}$/
          })}
          error={!!errors.password}
          helperText={errors.password && 'Password is required'}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          {...register("confirmPassword", {
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{}|;:,.<>?]).{8,}$/,
            validate: validateConfirmPassword,
          })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword && 'Passwords must match'}
        />
      </>
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form >
          {buildEmail()}
          {buildPassword()}
          {isAdmin
            ?
            <>
              <InputLabel id="theme-label">Client</InputLabel>
              <Select
                sx={{ marginBottom: '10px', width: '50%' }}
                label="Select Client"
                {...register("clientId", { required: true })}
                error={!!errors.clientId}
                helperText={errors.clientId ? 'Client is required' : ''}
              >
                {clientList.map((item) => {
                  return (
                    <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                  );

                })}
              </Select>
            </>
            : <></>
          }
          <Controller
            control={control}
            name="groups"
            defaultValue={[]}
            rules={{
              required: "Groups are required",
              validate: validateGroups
            }}
            render={({ field: { value, setValue } }) => (
              <MultiSelector
                label='User Groups'
                value={value}
                valueList={allowedGroups}
                width='50%'
                setValue={handleSetGroups}
              />
            )}
          />
          {errors.groups && <FormHelperText>{errors.groups.message}</FormHelperText>}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
          {saveLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserForm.defaultProps = {
  user: null,
  groupList: [],
  isAdmin: true,
};

UserForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  user: PropTypes.object,
  groupList: PropTypes.array,
  isAdmin: PropTypes.bool,
  clientList: PropTypes.array.isRequired,
};

export default UserForm;
