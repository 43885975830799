import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const AlertPopup = ({
  open,
  title,
  message,
  onCancel,
  onOk,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item>
            <WarningIcon color="warning" />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" align="center">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default AlertPopup;
