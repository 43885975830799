import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../util/APIDataLoader';

function CustomerAnalysisDashboard( { filterParam, setLoading }) {
    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('customerAnalysis', ConstructFilterParams(filterParam), setLoading, 'customerAnalysisDashboardContainer');
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="customerAnalysisDashboardContainer"></div>
    );
}

export default CustomerAnalysisDashboard;
