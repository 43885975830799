import dayjs from 'dayjs';
import { useState, useEffect, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useAuthContext } from '../../../store/AuthContext';
import CustomerAnalysisDashboard from "./CustomerAnalysisDashboard";
import { GetQueryParams } from '../../../util/Utility';
import {
    SubmitButton,
    CustomerSelector,
    CalendarSelector
} from '../../UI/UIComponents';

function CustomerAnalysisContent() {
    const { clientConfig } = useAuthContext();

    const [customer, setCustomer] = useState('');
    const [filterParam, setFilterParam] = useState();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(12, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [initialCustomer, setInitialCustomer] = useState('');
    const queryParams = GetQueryParams();

    const { customerFilterData } = useLoaderData();
    const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);  // TODO set to 'false' if page needs to auto load, otherwise remove

    let minDate;
    if (clientConfig?.filters?.yearSpan) {
        minDate = dayjs().subtract(clientConfig.filters.yearSpan, 'year');
    }

    const customerList = useMemo(() => customerFilterData.data.result.Items.map(item => {
        return {
            ...item,
            value: item.accountnumber
        }
    }), [customerFilterData]);

    useEffect(() => { // TODO remove if page doesn't need to auto load
        if (!queryFiredOnLoad) {
            const queryCustomer = queryParams.get("customer");
            let runQuery = false;
            if (queryCustomer != null) {
                setCustomer(queryCustomer);
                setInitialCustomer(queryCustomer);
                runQuery = true;
            }

            if (runQuery) {
                setQueryFiredOnLoad(true);
                setLoading(true);
                setFilterParam({
                    'accountNumber': queryCustomer ?? customer,
                    'startDate': startDate,
                    'endDate': endDate,
                });
            }
        }
    }, [customer, endDate, queryFiredOnLoad, queryParams, startDate]);

    const filterHandler = () => {
        setLoading(true);
        setFilterParam({
            'accountNumber': customer,
            'startDate': startDate,
            'endDate': endDate,
        });
    }

    return (
        <div className="mainContent">
            <div className=" pl-32 pr-32">
                <div className="TitleSearch mt-26 mb-26 d-flex align-items-center justify-content-between">
                    <h2 className="mainContentTitle">
                        Customer Analysis
                    </h2>
                </div>
                <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
                    <div className=" customerBillingForm spark-search-tab-card">
                        <form action="" method="post">
                            <div className="row mt-26">
                                <CustomerSelector
                                    label='Customer'
                                    onSelect={setCustomer}
                                    startingValue={initialCustomer}
                                    customers={customerList}
                                />

                                <div className="col-1" />

                                <CalendarSelector
                                    dateValue={startDate}
                                    setDateValue={setStartDate}
                                    minDate={minDate}
                                    disableFuture={true}
                                    views={["year", "month"]}
                                    label="Start Date"
                                />

                                <CalendarSelector
                                    dateValue={endDate}
                                    setDateValue={setEndDate}
                                    minDate={startDate}
                                    disableFuture={true}
                                    views={["year", "month"]}
                                    label="End Date"
                                />

                                <div className="col-3" />

                                <SubmitButton
                                    filterHandler={filterHandler}
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <CustomerAnalysisDashboard filterParam={filterParam} setLoading={setLoading} />
        </div>
    );
}

export default CustomerAnalysisContent;