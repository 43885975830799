import React, { useState, useEffect } from 'react';
import { MenuItem, Select, ListItemText } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

export function SingleSelector( { value, setValue, valueList, label, width } ) {
    const valueHandler = (event) => {
        setValue(event.target.value);
    }

    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        if(valueList.length === 1) {
            setValue(valueList[0].value);
            setReadOnly(true);
        }
    }, [setValue, setReadOnly, valueList]);

    const selector = (
        <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            displayEmpty
            value={value}
            onChange={valueHandler}
            readOnly={readOnly}
            renderValue={(selected) => {
                if (!selected || selected.length === 0) {
                    return <span>Select {label}</span>;
                }

                return selected;
            }}
            // MenuProps={MenuProps}
        >
            {valueList.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                    <ListItemText primary={name.label ?? name.value} />
                </MenuItem>
            ))}
        </Select>
    );
    if(width) {
        return (
            <div style={{ width: width }} className="mb-lg-0 mb-1">
                <label htmlFor="rateClass" className="form-label">{label}</label>

                <div className='row m-0'>
                    {selector}
                </div>
            </div>
        );
    }
    return (
        <div className="col-lg-2 col-md-6 mb-lg-0 mb-1">
            <label htmlFor="rateClass" className="form-label">{label}</label>

            <div className='row me-3'>
                {selector}
            </div>
        </div>
    );
}
