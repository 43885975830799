import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, Auth } from 'aws-amplify';
import { SearchSelector } from './SearchSelector';

const CustomerSelector = ({ label, onSelect, startingValue, customers }) => {
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const startCustomers = startingValue && customers.findIndex((customer) => customer.value === startingValue) === -1 ? [{accountnumber: startingValue, value: startingValue}] : [];
    // console.log(startCustomers);
    setCustomerList([...customers, ...startCustomers].sort((a, b) => {
      return (a.accountnumber === b.accountnumber);
    }));
  }, [customers, startingValue]);

  const onCustomerSearch = async (searchTerm) => {
    setLoading(true);
    // console.log(`onSearch: ${searchTerm}`);
    const data = await Auth.currentSession();
    const { idToken: {
      jwtToken,
      payload: {
        sub }
    }
    } = data;

    const params = {
      headers: {},
      response: true,
      queryStringParameters: {
        jwtToken: jwtToken,
        payloadSub: sub,
        query: 'customerSearchSelector',
        param: `${searchTerm}%`
      }
    }
    API.get('GridFin', '/common/query', params).then((data) => {
      // console.log(`onSearch: found ${data?.data?.result?.Items?.length} items`);
      const list = data.data.result.Items.map(item => {
        return {
          ...item,
          value: item.accountnumber
        }
      });
      const mergeList = [...customerList, ...list];
      const mergeUnique = mergeList.filter((obj, index, self) => {
        return index === self.findIndex((o) => (
          o.accountnumber === obj.accountnumber
        ));
      });
      mergeUnique.sort((a, b) => {
        return (a.accountnumber === b.accountnumber);
      });
      setLoading(false);
      setCustomerList(mergeUnique);
    });
  };

  const onCustomerSelect = (customer) => {
    // console.log(`onSelect: ${JSON.stringify(customer, null, 2)}`);
    onSelect(customer.value);
  };

  return (
    <SearchSelector
      label={label}
      loading={loading}
      onSearch={onCustomerSearch}
      onSelect={onCustomerSelect}
      startingValue={startingValue}
      options={customerList}
    />
  );
};

CustomerSelector.defaultProps = {
  label: 'Customer'
};

CustomerSelector.propTypes = {
  label: PropTypes.string,
  startingValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    value: PropTypes.string.isRequired
  })),
};

export { CustomerSelector };
