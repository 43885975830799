import React, { useState, useEffect } from "react";
import { API, Auth, } from 'aws-amplify';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
  Alert,
  CircularProgress,
  Container,
  Box,
} from '@mui/material';

import { useAuthContext } from '../../../store/AuthContext';
import UserForm from './UserForm';
import AlertPopup from '../../AlertPopup/AlertPopup';

const rowsPerPage = 20;

const UserAdminContent = () => {
  const { user, userGroups, clientId } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [alertTitleDelete, setAlertTitleDelete] = useState('');
  const [alertMessageDelete, setAlertMessageDelete] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadUsers, setLoadUsers] = useState(false);
  const [tenantList, setTenantList] = useState([]);

  useEffect(() => {
    const getUserGroups = async () => {
      try {
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          }
        }
        const data = await API.get('AdminQueries', '/listGroups', options);
        const groupdata = data.Groups.map((group) => { return { value: group.GroupName } });
        setGroups(groupdata);
      } catch (error) {
        console.log(error);
        setMessage(error?.response?.data?.message);
        setSeverity('error');
      }
    };

    const getGroupsForUser = async (userItem) => {
      try {
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          }
        }
        const data = await API.get('AdminQueries', `/listGroupsForUser?username=${userItem.Username}`, options);

        return data.Groups;
      } catch (error) {
        console.log(error);
        setMessage(error?.response?.data?.message);
        setSeverity('error');
      }
    };

    const getUsers = async () => {
      try {
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          }
        }
        // If user is Support then they can only see users with the same clientId
        let path = '/listUsers';
        if (userGroups.includes('Admin')) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
          path = `/listUsers?clientId=${clientId}`;
        }
        let paginationToken = null;
        let listUsers = [];
        do {
          if (paginationToken) {
            path = `${path}&token=${paginationToken}`;
          }
          const listResult = await API.get('AdminQueries', path, options);
          listUsers = listUsers.concat(listResult.Users);
          paginationToken = listResult.PaginationToken;
        } while (paginationToken);

        listUsers = await Promise.all(listUsers.map(async (item) => {
          const groups = await getGroupsForUser(item);
          const groupArray = groups.map((group) => {
            return group.GroupName;
          });
          const Email = item.Attributes.find((attr) => attr.Name === 'email').Value;
          let Client = item.Attributes.find((attr) => attr.Name === 'custom:client');
          Client = (Client) ? Client.Value : '';

          return {
            ...item,
            Groups: groupArray.join(', '),
            Email,
            Client,
          };
        }));

        listUsers.sort((a, b) => {
          if (a.Client === b.Client) {
            // If Clients are equal, sort by Email
            return a.Email.localeCompare(b.Email);
          }
          // Otherwise, sort by Client
          return a.Client.localeCompare(b.Client);
        });
        setUsers(listUsers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        setMessage(error?.response?.data?.message);
        setSeverity('error');
      }
    };

    const getClientList = async () => {
      try {
        const authData = await Auth.currentSession();
        const { idToken: {
          jwtToken,
          payload: {
            sub }
        }
        } = authData;
        const params = {
          headers: {},
          response: true,
          queryStringParameters: {
            jwtToken: jwtToken,
            payloadSub: sub,
          }
        }
        const listResult = await API.get('GridFin', '/common/client/list', params);
        const data = listResult.data.map((item) => {
          return { value: item, label: item };

        });
        setTenantList(data);
      } catch (error) {
        console.log(error);
        setMessage(error?.response?.data?.message);
        setSeverity('error');
      }
    };

    if (user && userGroups && clientId) {
      setLoading(true);
      getUsers();
      getUserGroups();
      getClientList();
    }
  }, [user, userGroups, clientId, loadUsers]);

  const handleDelete = (user) => {
    setMessage('');
    setSelectedUser(user);
    setAlertTitleDelete('Delete User');
    setAlertMessageDelete(`Are you sure you want to delete the user ${user.Email}?`);
    setOpenAlertDelete(true);
  };

  const handleCloseAlertDelete = () => {
    setOpenAlertDelete(false);
  };

  const handleOkAlertDelete = async () => {
    setLoading(true);
    console.log(`Deleting user: ${selectedUser.Username}/${selectedUser.Email}`);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user.signInUserSession.accessToken.jwtToken}`
      },
      body: {
        username: selectedUser.Username,
      },
    }
    await API.post('AdminQueries', '/disableUser', options);
    await API.post('AdminQueries', '/deleteUser', options);
    setSelectedUser(null);
    setMessage(`${selectedUser.Email} deleted.`);
    setSeverity('success');
    setLoading(false);
    setLoadUsers(true);
    setOpenAlertDelete(false);
  };

  const handleModify = (user) => {
    setMessage('');
    setSelectedUser(user);
    setOpenUserDialog(true);
  };

  // const handleForcePasswordChange = async (user) => {
  //   try {
  //     setLoading(true);
  //     await Auth.forgotPassword(user.Username);
  //     setMessage(`Password reset confirmation code has been sent to ${user.Email}.`);
  //     setSeverity('success');
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setMessage(error?.response?.data?.message);
  //     setSeverity('error');
  //     setLoading(false);
  //   }
  // };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setSelectedUser(null);
  };

  const modifyUser = async (input) => {
    setLoading(true);
    const { groups, clientId: newClientId } = input;
    try {
      if (isAdmin) {
        // Admin can update clientId
        console.log(`Updating clientId: ${newClientId}`);
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          },
          body: {
            username: selectedUser.Username,
            clientid: newClientId,
          },
        }
        await API.post('AdminQueries', '/addUserClientId', options);
      }
      let selectedUserGroups = selectedUser.Groups.split(',');
      selectedUserGroups = selectedUserGroups.map((group) => group.trim());
      // Groups to remove
      const groupsToRemove = selectedUserGroups.filter((group) => !groups.includes(group));
      console.log(`Removing groups: ${JSON.stringify(groupsToRemove, null, 2)}`);
      groupsToRemove.forEach(async (groupname) => {
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          },
          body: {
            username: selectedUser.Username,
            groupname,
          },
        }
        await API.post('AdminQueries', '/removeUserFromGroup', options);
      });

      // Groups to add
      const groupsToAdd = groups.filter((group) => !selectedUserGroups.includes(group));
      console.log(`Adding groups: ${JSON.stringify(groupsToAdd, null, 2)}`);
      groupsToAdd.forEach(async (groupname) => {
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          },
          body: {
            username: selectedUser.Username,
            groupname,
          },
        }
        await API.post('AdminQueries', '/addUserToGroup', options);
      });

      setMessage('User has been modified.');
      setSeverity('success');
      setLoadUsers(true);
    } catch (error) {
      setMessage(`Error modifying user. ${error}`);
      setSeverity('error');
      setLoading(false);
    }
  };

  const createUser = async (input) => {
    setLoading(true);
    try {
      const { email, password, groups } = input;
      const existingUser = users.find((user) => user.Email === email);
      if (existingUser) {
        setMessage(`User with email ${email} already exists.`);
        setSeverity('error');
        setLoading(false);
        return;
      }
      let userClientId = input.clientId;
      // Support users can only create users with the same clientId
      if (!isAdmin) {
        userClientId = clientId;
      }
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.signInUserSession.accessToken.jwtToken}`
        },
        body: {
          email,
          password,
          clientId: userClientId,
          groups,
        },
      }
      await API.post('AdminQueries', '/createUser', options);
      setMessage('User created.');
      setSeverity('success');
      setLoadUsers(true);
    } catch (error) {
      setMessage(`Error creating user. ${error}`);
      setSeverity('error');
      setLoading(false);
    }
  };

  const handleSaveUser = (data) => {
    setOpenUserDialog(false);
    if (selectedUser) {
      modifyUser(data);
      setSelectedUser(null);
    } else {
      createUser(data);
    }
  };

  const handleCreate = () => {
    setMessage('');
    setSelectedUser(null);
    setOpenUserDialog(true);
  };

  const handleChangePage = (event, value) => {
    setMessage('');
    setPage(value);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedData = users.slice(startIndex, endIndex);

  const buildMessage = () => {
    if (!message || message.length === 0) {
      return (<></>);
    }

    return (
      <Alert severity={severity}>{message}</Alert>
    )
  };

  const buildLoading = () => {
    if (!loading) {
      return (<></>);
    }

    return (
      <CircularProgress />
    )
  };

  return (
    <Container style={{ "maxWidth": '100%' }}>
      {buildMessage()}
      {buildLoading()}
      <Box sx={{ marginTop: '20px' }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: 'calc(100vh - 250px)' }}
        >
          <Table
            stickyHeader
            sx={{ marginTop: '20px' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleCreate}
                  >
                    Create
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedData.map((item) => {
                return (
                  <TableRow key={item.Username}>
                    <TableCell>{item.Email}</TableCell>
                    <TableCell>{item.Groups}</TableCell>
                    <TableCell>{item.Client}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => handleDelete(item)}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => handleModify(item)}
                        >
                          Modify
                        </Button>
                        {/* <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => handleForcePasswordChange(item)}
                        >
                          Force Password Change
                        </Button> */}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(users.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
        <UserForm
          open={openUserDialog}
          onCancel={handleCloseUserDialog}
          onSave={handleSaveUser}
          user={selectedUser}
          groupList={groups}
          isAdmin={isAdmin}
          clientList={tenantList}
        />
        <AlertPopup
          open={openAlertDelete}
          title={alertTitleDelete}
          message={alertMessageDelete}
          onCancel={handleCloseAlertDelete}
          onOk={handleOkAlertDelete}
        />
      </Box>
    </Container>
  );
};

export default UserAdminContent;
