
import CustomerOverviewContent from "../../components/RevenueFromService/CustomerOverview/CustomerOverviewContent";
import { APIDataLoader } from "../../util/APIDataLoader";

function CustomerOverview() {
    return (
        <CustomerOverviewContent />
    )
}

export default CustomerOverview;

export async function loader() {
    const promises = await Promise.all([
        APIDataLoader('rateSelector'),
        APIDataLoader('gridLocationSelector'),
    ]);

    return {
        rateClassesData: promises[0],
        gridLocationFilterData: promises[1]
    };
}
