import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import gridFinLogoLight from "../../assets/images/gridFinLogoLight.png";
import gridFinLogoDark from "../../assets/images/gridFinLogoDark.png";
import './SideBarMenu.css'
import { useAuthContext } from '../../store/AuthContext';

import { Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, Collapse, Link } from '@mui/material';
import { ArrowRight, ArrowDropDown } from "@mui/icons-material";

const pageDefinitions = [
    {
        access: 'Home',
        name: 'System Overview',
        path: '/',
        pages: [
            {
                name: 'Executive Summary',
                path: '',
            },
        ]
    },
    {
        access: 'CostOfService',
        name: 'Cost Of Service',
        path: '/costOfService',
        pages: [
            {
                name: 'Supplier Analysis',
                path: '/supplierAnalysis',
            },
            {
                name: 'Bill Audit',
                path: '/billManager',
            },
        ]
    },
    {
        access: 'RevenueFromService',
        name: 'Revenue From Service',
        path: '/revenueFromService',
        pages: [
            {
                name: 'Customer Base Overview',
                path: '/customerBaseOverview',
                // children: [
                //     { name: 'Summary' },
                //     { name: 'Outliers' },
                //     { name: 'Heatmap' },
                // ]
            },
            {
                name: 'Customer Analysis',
                path: '/customerAnalysis',
            },
            {
                name: 'Shadow Billing',
                path: '/billManager',
            },
        ]
    },
    {
        access: 'PowerPNL',
        name: 'Power P&L',
        path: '/powerPNL',
        pages: [
            {
                name: 'Rate Analysis',
                path: '/rateAnalysis',
            },
            {
                name: 'What If Scenario',
                path: '/whatIfScenario',
            },
        ]
    },
];

function SideBarMenu({ drawerWidth }) {
    const navigate = useNavigate();
    const { accessList, hasAccess, clientConfig } = useAuthContext();

    const pathname = window.location.pathname;

    const [menuStates, setMenuStates] = useState(pageDefinitions.filter(definition => definition.pages.length > 0).flatMap(definition => definition.pages).filter(page => page.children !== undefined).map(page => {
        return {
            [`lnk${page.name.trim()}`]: false,
            lnkHome: pathname === '/',
            lnkCostOfService: pathname.includes('/costOfService'),
            lnkRevenueFromService: pathname.includes('/revenueFromService'),
            lnkPowerPNL: pathname.includes('/powerPNL'),
            lnkAdmin: pathname.includes('/admin')
        }
    }));

    const AddHomeNavigation = () => {
        return (
            <>
                <List key="home-nav" variant="sidebar-navigation">
                    <ListItem variant="sidebar-home">
                        <Link underline="none" color="inherit" href='/' onClick={(event) => { event.preventDefault(); toggleMenu('lnkHome'); navigate('/'); }}>
                            <img src={clientConfig?.theme?.gridfin?.name === "dark" ? gridFinLogoDark : gridFinLogoLight} alt="" />
                        </Link>
                    </ListItem>
                </List>
                <Divider />
            </>
        );
    }

    const AddPageNavigation = () => {
        return (
            <List key="page-navigation" variant="sidebar-navigation">
                {pageDefinitions.map(definition => {
                    // return (<React.Fragment key={`fragment-${definition.access}`}></React.Fragment>);
                    if (hasAccess(accessList[definition.access])) {
                        return (
                            <React.Fragment key={`fragment-${definition.access}`}>
                                <ListItem key={`header-${definition.access}`} variant="sidebar-navigation-header">
                                    {definition.name}
                                </ListItem>
                                {definition.pages.map(pageDefinition => {
                                    if (pageDefinition.children !== undefined) {
                                        return (
                                            <>
                                                <ListItemButton key={`${definition.access}-${pageDefinition.name.trim()}`} variant="sidebar-navigation-button" selected={menuStates[`lnk${pageDefinition.name.trim()}`]} onClick={() => toggleMenu(`lnk${pageDefinition.name.trim()}`)}>
                                                    <ListItemIcon>
                                                        {menuStates[`lnk${pageDefinition.name.trim()}`] ? <ArrowDropDown /> : <ArrowRight />}
                                                    </ListItemIcon>
                                                    {pageDefinition.name}
                                                </ListItemButton>
                                                {pageDefinition.children.length > 0 &&
                                                    <Collapse key={`sub-${definition.access}`} in={menuStates[`lnk${pageDefinition.name.trim()}`]} timeout="auto" unmountOnExit>
                                                        <List key={`subnav-${definition.access}`} variant="sidebar-subnavigation">
                                                            {pageDefinition.children.map(childDef => {
                                                                return (
                                                                    <ListItemButton key={`${definition.access}-${pageDefinition.name.trim()}-${childDef.name.trim()}`} variant="sidebar-subnavigation-button" selected={pathname === `${definition.path}${pageDefinition.path}`}>
                                                                        <Link underline="none" color="inherit" href={`${definition.path}${pageDefinition.path}?Tab=${childDef.name.trim()}`} onClick={(event) => { event.preventDefault(); navigate(`${definition.path}${pageDefinition.path}?Tab=${childDef.name.trim()}`); }}>
                                                                            {childDef.name}
                                                                        </Link>
                                                                    </ListItemButton>
                                                                );
                                                            })}
                                                        </List>
                                                    </Collapse>
                                                }
                                            </>
                                        );
                                    } else {
                                        return (
                                            <ListItemButton key={`${definition.access}-${pageDefinition.name.trim()}`} variant="sidebar-navigation-button" selected={pathname === `${definition.path}${pageDefinition.path}`}>
                                                <Link underline="none" color="inherit" href={`${definition.path}${pageDefinition.path}`} onClick={(event) => { event.preventDefault(); navigate(`${definition.path}${pageDefinition.path}`); }}>
                                                    {pageDefinition.name}
                                                </Link>
                                            </ListItemButton>
                                        );
                                    }
                                })
                                }
                            </React.Fragment>
                        );
                    };
                    return (<React.Fragment key={`fragment-${definition.access}`}></React.Fragment>)
                })}
            </List>
        )
    };

    const AddAdmin = () => {
        if (hasAccess(accessList.Admin)) {
            return (
                <>
                    <Divider />
                    <List key="admin-navigation" variant="sidebar-navigation">
                        <ListItemButton key="admin" variant="sidebar-navigation-button" selected={pathname === '/admin'}>
                            <Link underline="none" color="inherit" href='/admin'>
                                Admin
                            </Link>
                        </ListItemButton>
                    </List>
                </>
            );
        }

        return (<></>);
    };

    function toggleMenu(menuName) {
        setMenuStates(prevState => {
            const updatedStates = { ...prevState };
            updatedStates[menuName] = !prevState[menuName];
            // for (const key in prevState) {
            //     updatedStates[key] = key === menuName ? !prevState[key] : false;
            // }
            return updatedStates;
        });
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            {AddHomeNavigation()}
            {AddPageNavigation()}
            {AddAdmin()}
        </Drawer>
    )
}

export default SideBarMenu;