import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth, Hub, API } from 'aws-amplify';

import {
  setStorageItem,
  getStorageItem
} from '../util/AppStorage';

const AuthContext = createContext();
const accessList = {
  Home: 'Home',
  CostOfService: 'CostOfService',
  RevenueFromService: 'RevenueFromService',
  PowerPNL: 'PowerPNL',
  Admin: 'Admin',
  CreateTenant: 'CreateTenant',
  Preferences: 'Preferences',
};

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [clientConfig, setClientConfig] = useState(null);
  const [reloadClientConfig, setReloadClientConfig] = useState(false);

  const resetState = () => {
    setUser(null);
    setUserEmail(null);
    setUserGroups([]);
    setStorageItem('userGroups', []);
    setClientId(null);
    setClientConfig(null);
  };

  useEffect(() => {
    const getClientConfig = async () => {
      if (user) {
        const {
          signInUserSession: {
            idToken: {
              jwtToken,
              payload: {
                sub: payloadSub
              }
            }
          }
        } = user;

        const params = {
          headers: {},
          response: true,
          queryStringParameters: {
            jwtToken: jwtToken,
            payloadSub: payloadSub
          }
        }
        const data = await API.get('GridFin', '/common/client/properties', params);
        // override with local preferences
        const localGridfinTheme = localStorage.getItem('gridfinTheme');
        if (localGridfinTheme) {
          data.data.theme.gridfin.name = localGridfinTheme;
        } else {
          // Store it so that we can apply theme before getting config data
          localStorage.setItem('gridfinTheme', data.data.theme.gridfin.name);
        }
        const localGridfinDashboardTheme = localStorage.getItem('gridfinDashboardTheme');
        if (localGridfinDashboardTheme) {
          data.data.theme.quickSight.themeArn = localGridfinDashboardTheme;
        }
        const localCacheEnabled = localStorage.getItem('gridfinCacheEnabled');
        if (localCacheEnabled) {
          data.data.cache.enabled = (localCacheEnabled === 'true');
        }
        const localCacheTTL = localStorage.getItem('gridfinCacheTTL');
        if (localCacheTTL) {
          data.data.cache.ttl = parseInt(localCacheTTL, 10);
        }

        setClientConfig(data.data);
      }
    };
    getClientConfig();
  }, [user, reloadClientConfig]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
        const userAttributes = await Auth.userAttributes(userData);

        // Fetch user email
        const email = userAttributes.find((attr) => attr.getName() === 'email');
        setUserEmail(email.getValue());
        // Fetch custom attribute clientId
        const client = userAttributes.find((attr) => attr.getName() === 'custom:client');
        if (client) {
          setClientId(client.getValue());
        } else {
          setClientId(null);
        }
        // Fetch user groups
        const groups = userData.signInUserSession.accessToken.payload["cognito:groups"];
        setUserGroups(groups);
        setStorageItem('userGroups', groups);
      } catch (error) {
        resetState();
      }
    };
    getUserData();

    const authListener = async (data) => {
      console.log(`authEvent: ${data.payload.event}`);
      switch (data.payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
        case 'tokenRefresh':
          getUserData();
          break;
        case 'signOut':
          resetState();
          break;
        default:
          break;
      }
    };
    const hubListenerCancelToken = Hub.listen('auth', authListener);

    return () => hubListenerCancelToken();;
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const costofService = [
    'Admin',
    'ClientAdmin',
    'Support',
    'Analyst',
    'Executive',
    'Manager',
  ];
  const revenueFromService = [
    'Admin',
    'ClientAdmin',
    'Support',
    'Analyst',
    'Executive',
    'Manager',
  ];
  const powerPNL = [
    'Admin',
    'ClientAdmin',
    'Support',
    'Analyst',
    'Executive',
    'Manager',
  ];
  const admin = [
    'Admin',
    'ClientAdmin',
    'Manager',
  ];

  const hasAccess = (access) => {
    let groups = userGroups;
    if (!groups || groups.length === 0) {
      groups = getStorageItem('userGroups');
    }

    switch (access) {
      case accessList.Home:
      case accessList.Preferences:
        return true;
      case accessList.CostOfService:
        return (groups?.some(item => costofService.includes(item)));
      case accessList.RevenueFromService:
        return (groups?.some(item => revenueFromService.includes(item)));
      case accessList.PowerPNL:
        return (groups?.some(item => powerPNL.includes(item)));
      case accessList.Admin:
        return (groups?.some(item => admin.includes(item)));
      case accessList.CreateTenant:
        return (groups?.some(item => item === 'Admin'));
      default:
        return false;
    }
  };

  const setGridfinTheme = (value) => {
    if (clientConfig === null) {
      return;
    }
    const data = {
      ...clientConfig,
      theme: {
        ...clientConfig.theme,
        gridfin: {
          ...clientConfig.theme.gridfin,
          name: value
        }
      }
    };
    setClientConfig(data);
  };

  const setGridfinThemeDashboard = (value) => {
    if (clientConfig === null) {
      return;
    }
    const data = {
      ...clientConfig,
      theme: {
        ...clientConfig.theme,
        quickSight: {
          ...clientConfig.theme.quickSight,
          themeArn: value
        }
      }
    };
    setClientConfig(data);
  };

  const setGridfinCache = (value) => {
    const data = {
      ...clientConfig,
      cache: { ...value }
    };
    setClientConfig(data);
  };

  const reloadConfig = () => {
    setReloadClientConfig(true);
  };

  const contextValue = {
    user,
    userEmail,
    userGroups,
    clientId,
    clientConfig,
    signOut,
    accessList,
    hasAccess,
    setGridfinTheme,
    setGridfinThemeDashboard,
    setGridfinCache,
    reloadConfig,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContextProvider');
  }
  return context;
};
