import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../../util/APIDataLoader';

function BillAuditDashboard( { filterParam, setLoading }) {

    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('supplierBillAudit', ConstructFilterParams(filterParam), setLoading, "billAuditDashboardContainer");
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="billAuditDashboardContainer"></div>
    );
}

export default BillAuditDashboard;
