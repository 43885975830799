import { Outlet, useNavigation, useLocation } from "react-router-dom";
import { Box, Toolbar } from '@mui/material';
import Header from "../components/Header/Header";
import SideBarMenu from "../components/SideBarMenu/SideBarMenu";
import { useAuthContext } from "../store/AuthContext";
import NotAuthorizedPage from "./NotAuthorized";
import { Div } from "../components/UI/UIComponents";

const drawerWidth = 250;


function Layout() {
    const navigation = useNavigation();
    const location = useLocation();
    const { accessList, hasAccess, } = useAuthContext();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <Div variant="root-content">
            <Header drawerWidth={drawerWidth}></Header>
            <SideBarMenu drawerWidth={drawerWidth}></SideBarMenu>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3 }}
            >
                <Toolbar />
                {navigation.state === 'loading' && <p>loading...</p>}

                {pathnames.length === 0 || hasAccess(accessList[pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1)]) ?
                    <Outlet /> :
                    <NotAuthorizedPage />
                }
            </Box>
        </Div>
    );
}

export default Layout;