
import SupplierAnalysisContent from "../../components/CostOfService/SupplierAnalysis/SupplierAnalysisContent";
import { APIDataLoader } from "../../util/APIDataLoader";

function SupplierAnalysis() {
    return (
        <SupplierAnalysisContent />
    )
}

export default SupplierAnalysis;

export async function loader() {
    return await Promise.all([APIDataLoader('supplierSelector')]);
}
