import React, { useState, useEffect } from "react";
import { API, Auth } from 'aws-amplify';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import dayjs from 'dayjs';

import { CalendarSelector } from '../../UI/UIComponents';
import { useAuthContext } from '../../../store/AuthContext';

const ConfigAdminContent = () => {
  const {
    user,
    reloadConfig,
    accessList,
    hasAccess,
    clientId,
  } = useAuthContext();

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [tenantProperties, setTenantProperties] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [tenant, setTenant] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const [createTenant, setCreateTenant] = useState('');
  const [themeList, setThemeList] = useState([
    { value: 'light', label: 'light' },
    { value: 'dark', label: 'dark' }
  ]);
  const [quickSIghtThemeList, setQuickSightThemeList] = useState([]);
  const [minDate, setMinDate] = useState(dayjs().subtract(2, 'year'));

  useEffect(() => {
    const getConfigList = async () => {
      try {
        // Only Admin can create and modify any tenant
        if (hasAccess(accessList.CreateTenant)) {
          setMessage('');
          setLoading(true);
          const authData = await Auth.currentSession();
          const { idToken: {
            jwtToken,
            payload: {
              sub }
          }
          } = authData;
          const params = {
            headers: {},
            response: true,
            queryStringParameters: {
              jwtToken: jwtToken,
              payloadSub: sub,
            }
          }
          const listResult = await API.get('GridFin', '/common/client/list', params);
          const data = listResult.data.map((item) => {
            return { value: item, label: item };

          });
          setTenantList(data);
          setTenant(data[0].value);
          setLoading(false);
        } else {
          setTenant(clientId);
        }
      } catch (error) {
        console.log(error);
        setMessage(error?.response?.data?.message);
        setSeverity('error');
        setLoading(false);
      }
    };

    const getQuickSightThemeList = async () => {
      const authData = await Auth.currentSession();
      const { idToken: {
        jwtToken,
        payload: {
          sub }
      }
      } = authData;
      const params = {
        headers: {},
        response: true,
        queryStringParameters: {
          jwtToken: jwtToken,
          payloadSub: sub,
        }
      }
      const listResult = await API.get('GridFin', '/common/quicksighthemes', params);
      const data = listResult.data.themes.map((item) => {
        return {
          ...item,
          value: item.Arn,
          label: item.Name,
        };
      });
      data.unshift({ value: 'None', label: 'None' });
      setQuickSightThemeList(data);
    };

    getConfigList();
    getQuickSightThemeList();
  }, [accessList.CreateTenant, clientId, hasAccess]);

  useEffect(() => {
    const getProperties = async () => {
      try {
        setMessage('');
        setTenantProperties(null);
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${user.signInUserSession.accessToken.jwtToken}`
          }
        }
        const data = await API.get('AdminQueries', `/getClientProperties?client=${tenant}`, options);
        setTenantProperties(data);
      } catch (error) {
        console.log(error);
        setMessage(error?.response?.data?.message);
        setSeverity('error');
      }
    };

    if (tenant && tenant.length > 0) {
      setMessage('');
      getProperties();
    }
  }, [tenant, user]);

  useEffect(() => {
    if (tenantProperties) {
      const data = tenantProperties.theme.list.map((item) => {
        return { value: item, label: item };
      });
      setThemeList(data);

      if (tenantProperties?.filters?.yearSpan && tenantProperties?.filters?.startYear) {
        setMinDate(dayjs(`${tenantProperties.filters.startYear} January 01`, 'YYYY MMMM DD').subtract(tenantProperties.filters.yearSpan, 'year'));
      }
    }
  }, [tenantProperties]);

  const buildLoading = () => {
    if (!loading) {
      return (<></>);
    }

    return (
      <CircularProgress />
    )
  };

  const buildMessage = () => {
    if (!message || message.length === 0) {
      return (<></>);
    }

    return (
      <Alert severity={severity}>{message}</Alert>
    )
  };

  const handleApplicationThemeChange = async (value) => {
    setTenantProperties({
      ...tenantProperties,
      theme: {
        ...tenantProperties.theme,
        gridfin: { name: value }
      }
    });
  };

  const handleDashboardThemeChange = (value) => {
    setMessage('');
    setTenantProperties({
      ...tenantProperties,
      theme: {
        ...tenantProperties.theme,
        quickSight: { themeArn: (value === 'None') ? '' : value }
      }
    });
  };

  const handleCacheEnabledChange = (value) => {
    setMessage('');
    setTenantProperties({
      ...tenantProperties,
      cache: {
        ...tenantProperties.cache,
        enabled: value
      }
    });
  };

  const handleDateFilterChange = (value) => {
    setMessage('');
    setTenantProperties({
      ...tenantProperties,
      filters: {
        ...tenantProperties.filters,
        startYear: value.format('YYYY'),
        startMonth: value.format('MMMM')
      }
    });
  };


  const handleSpanChange = (value) => {
    setMessage('');
    setTenantProperties({
      ...tenantProperties,
      filters: {
        ...tenantProperties.filters,
        yearSpan: Math.min(Math.max(value, 1), 10)
      }
    });
  };

  const handleSpanIncrement = () => {
    handleSpanChange(tenantProperties.filters.yearSpan + 1);
  };

  const handleSpanDecrement = () => {
    handleSpanChange(Math.max(tenantProperties.filters.yearSpan - 1, 1));
  };

  const GetFilterDate = () => {
    if (tenantProperties?.filters?.startYear && tenantProperties?.filters?.startMonth) {
      const filterDate = dayjs(`${tenantProperties.filters.startYear} ${tenantProperties.filters.startMonth} 01)}`, 'YYYY MMMM DD');
      return filterDate;
    }

    return dayjs().subtract(tenantProperties.filters.yearSpan, 'year');
  };

  const handleCacheLifespanChange = (value) => {
    setMessage('');
    setTenantProperties({
      ...tenantProperties,
      cache: {
        ...tenantProperties.cache,
        ttl: value
      }
    });
  };

  const handleSave = async () => {
    try {
      setMessage('');
      setLoading(true);
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.signInUserSession.accessToken.jwtToken}`
        },
        body: {
          client: tenant,
          content: tenantProperties,
        },
      }
      await API.post('AdminQueries', '/setClientProperties', options);
      setMessage('Settings saved');
      setSeverity('success');
      reloadConfig();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setMessage(error?.response?.data?.message);
      setSeverity('error');
      setLoading(false);
    }
  };

  const handleCreateClose = () => {
    setMessage('');
    setOpenCreate(false);
  };

  const handleCreate = async () => {
    try {
      setMessage('');
      setLoading(true);
      console.log(`createTenant: ${createTenant}`);
      const authData = await Auth.currentSession();
      const { idToken: {
        jwtToken,
        payload: {
          sub }
      }
      } = authData;
      const params = {
        headers: {},
        response: true,
        queryStringParameters: {
          jwtToken: jwtToken,
          payloadSub: sub,
        }
      }
      const defaultResult = await API.get('GridFin', '/common/client/defaultproperties', params);
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.signInUserSession.accessToken.jwtToken}`
        },
        body: {
          client: createTenant,
          content: defaultResult.data,
        },
      }
      await API.post('AdminQueries', '/createClient', options);
      setMessage(`Client ${createTenant} created`);
      setSeverity('success');
      reloadConfig();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    setOpenCreate(false);
  };

  const buildCreateClient = () => {
    if (!hasAccess(accessList.CreateTenant)) {
      return (
        <TextField
          label="Client"
          defaultValue={clientId}
          InputProps={{
            readOnly: true,
          }}
        />
      );
    }

    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <InputLabel id="theme-label">Client</InputLabel>
          <Select
            value={tenant}
            onChange={(e) => setTenant(e.target.value)}
            label="Client"
          >
            {tenantList.map((item) => {
              return (
                <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
              );

            })}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenCreate(true)}
          >
            Create Client
          </Button>
        </Grid>
      </Grid>
    );
  };

  const BuildSettings = () => {
    if (tenantProperties === null) {
      return (<></>);
    }

    return (
      <>
        <TableContainer
          component={Paper}
          sx={{ marginTop: '20px' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Setting</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Application Theme</TableCell>
                <TableCell colSpan={2}>
                  <Select
                    value={tenantProperties?.theme?.gridfin?.name}
                    onChange={(e) => handleApplicationThemeChange(e.target.value)}
                    label="Theme"
                  >
                    {themeList.map((item) => {
                      return (
                        <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                      );

                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dashboard Theme</TableCell>
                <TableCell colSpan={2}>
                  <Select
                    value={tenantProperties?.theme?.quickSight?.themeArn ? tenantProperties?.theme?.quickSight?.themeArn : ''}
                    onChange={(e) => handleDashboardThemeChange(e.target.value)}
                  >
                    {quickSIghtThemeList.map((item) => {
                      return (
                        <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                      );

                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Filter: Start Date
                  <Tooltip title="Default start for date selector.">
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell colSpan={2}>
                  <CalendarSelector
                    dateValue={GetFilterDate()}
                    minDate={minDate}
                    setDateValue={handleDateFilterChange}
                    disableFuture={true}
                    views={["year", "month"]}
                    blockClassName={""}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Filter: Year Span
                  <Tooltip title="Number of years a user is able to select for date range data.">
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    type="number"
                    size="small"
                    value={(tenantProperties?.filters?.yearSpan) ? tenantProperties.filters.yearSpan : '2'}
                    onChange={(e) => handleSpanChange(parseInt(e.target.value, 10) || 0)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleSpanIncrement} size="small">
                            <AddIcon />
                          </IconButton>
                          <IconButton onClick={handleSpanDecrement} size="small">
                            <RemoveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Cache Enabled
                  <Tooltip title="Data used for selection of Billing Cycle, Supplier etc. is locally cached.">
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell colSpan={2}>
                  <Checkbox
                    checked={tenantProperties?.cache?.enabled}
                    onChange={(e) => handleCacheEnabledChange(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cache Lifespan (1-10 Days)</TableCell>
                <TableCell colSpan={2}>
                  <Slider
                    value={tenantProperties?.cache?.ttl}
                    min={1}
                    max={10}
                    step={1}
                    marks
                    valueLabelDisplay="auto"
                    onChange={(e) => handleCacheLifespanChange(e.target.value)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const BuildCreateDialog = () => {
    return (
      <Dialog open={openCreate} onClose={handleCreateClose}>
        <DialogTitle>Create Tenant</DialogTitle>
        <DialogContent>
          <TextField
            label="Tenant"
            value={createTenant}
            onChange={(e) => setCreateTenant(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateClose}>Cancel</Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Container style={{ height: '100vh', overflow: 'auto' }}>
      {buildMessage()}
      {buildLoading()}
      <Box sx={{ marginTop: '20px' }}>
        {buildCreateClient()}
        {BuildSettings()}
      </Box>
      {BuildCreateDialog()}
    </Container>
  );
};

export default ConfigAdminContent;
