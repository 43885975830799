
import BillManagerContent from "../../components/CostOfService/BillManager/BillManagerContent";
import { APIDataLoader } from "../../util/APIDataLoader";

function BillManager() {
    return (
        <BillManagerContent />
    )
}

export default BillManager;

export async function loader() {;
    const promises = await Promise.all([
        APIDataLoader('supplierSelector'),
        APIDataLoader('billingSelector')
    ])

    return {
        supplierFilterData: promises[0],
        billingCycleFilterData: promises[1]
    };
}
