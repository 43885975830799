import React, { useState } from "react";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Button,
    Typography,
    Breadcrumbs,
    Link,
} from '@mui/material';
import { Settings, KeyboardArrowDown, Home } from '@mui/icons-material';

import { useAuthContext } from '../../store/AuthContext';


const breadcrumbNameMap = {
    '': 'Executive Summary',
    'costOfService': 'Cost Of Service',
    'costOfService/supplierAnalysis': 'Supplier Analysis',
    'costOfService/billManager': 'Bill Audit',
    'revenueFromService': 'Revenue From Service',
    'revenueFromService/customerBaseOverview': 'Customer Base Overview',
    'revenueFromService/billManager': 'Shadow Billing',
    'revenueFromService/customerAnalysis': 'Customer Analysis',
    'powerPNL': 'Power P&L',
    'powerPNL/whatIfScenario': 'What If Scenario',
    'powerPNL/rateAnalysis': 'Rate Analysis',
    'admin': 'Admin',
    'preferences': 'Preferences',
};

function Header( {drawerWidth} ) {
    const navigate = useNavigate();
    const location = useLocation();
    const { userEmail, signOut } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const pathnames = location.pathname === '/' ? [''] : location.pathname.split('/').filter((x) => x);

    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar
            position="fixed"
            sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
            <Toolbar>
                <Breadcrumbs aria-label="breadcrumb">
                    <LinkRouter underline="hover" color="inherit" to="/">
                        <Home />
                    </LinkRouter>
                    {pathnames.map((_, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `${pathnames.slice(0, index + 1).join('/')}`;
                        return last ? (
                            <Typography color="text.primary" key={to}>
                                {breadcrumbNameMap[to]}
                            </Typography>
                        ) : (
                            to in breadcrumbNameMap ? (
                                <Typography underline="hover" color="inherit" to={to} key={to}>
                                    {breadcrumbNameMap[to]}
                                </Typography>
                            ) : (
                                ""
                            )
                        );
                    })}
                </Breadcrumbs>
                <div style={{ marginRight: 'auto' }}></div>
                <IconButton onClick={() => navigate('/preferences')}>
                    <Settings />
                </IconButton>
                <Button
                    aria-controls={open ? 'header-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    disableElevation
                    variant="gridfin-header"
                    onClick={handleMenuOpen}
                    endIcon={<KeyboardArrowDown />}
                >
                    {userEmail}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => signOut()}>Sign Out</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

export default Header;