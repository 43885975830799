import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../util/APIDataLoader';

function WhatIfScenarioDashboard( { filterParam, setLoading }) {
    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('whatIfScenario', ConstructFilterParams(filterParam), setLoading, "whatIfScenarioDashboardContainer");
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="whatIfScenarioDashboardContainer"></div>
    );
}

export default WhatIfScenarioDashboard;
