import React, { useState, useEffect } from "react";
import { ThemeProvider, } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { useAuthContext } from './store/AuthContext';
import {
  gridFinThemes,
} from './config/theme';

let cacheConfig = {};
let themeConfig = {};

const AppLocalConfig = ({ children }) => {
  const { clientConfig, setGridfinTheme } = useAuthContext();
  const [theme, setTheme] = useState(gridFinThemes.light);

  useEffect(() => {
    if (clientConfig) {
      cacheConfig = clientConfig.cache;
      themeConfig = clientConfig.theme;
    }
    const localGridfinTheme = localStorage.getItem('gridfinTheme');
    if (localGridfinTheme && localGridfinTheme !== clientConfig?.theme?.gridfin?.name) {
      setTheme(gridFinThemes[localGridfinTheme]);
      setGridfinTheme(localGridfinTheme);
    } else if (clientConfig?.theme?.gridfin?.name in gridFinThemes) {
      setTheme(gridFinThemes[clientConfig.theme.gridfin.name]);
    }
  }, [clientConfig, setGridfinTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export {
  AppLocalConfig,
  cacheConfig,
  themeConfig,
};
