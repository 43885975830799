
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

import './App.css';
import AppRouter from './AppRouter';
import { AppLocalConfig } from './AppLocalConfig';
import { AuthContextProvider } from './store/AuthContext';

Amplify.configure(awsExports);

const App = () => {
  return (
    <AuthContextProvider>
      <AppLocalConfig>
        <AppRouter />
      </AppLocalConfig>
    </AuthContextProvider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });
