import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../util/APIDataLoader';

function RateAnalysisDashboard({ filterParam, setLoading }) {
    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if (filterParam !== undefined) {
                APIDashboardDataLoader('rateAnalysis', ConstructFilterParams(filterParam), setLoading, "rateAnalysisDashboardContainer");
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="rateAnalysisDashboardContainer"></div>
    );
}

export default RateAnalysisDashboard;
