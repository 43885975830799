// import React, { useState } from 'react';
import React from 'react';
// import BillHighlightsContent from './BillHighlights/BillHighlightsContent';
import BillShadowingContent from './BillShadowing/BillShadowingContent';

function CostOfServiceAnalysisBillManagerContent() {

    // const [tabStates, setTabStates] = useState({
    //     tabBillHighlights: false,
    //     tabBillShadowing: true
    // });

    // function toggleTabs(menuName) {
    //     console.log(menuName);
    //     setTabStates(prevState => {
    //         const updatedStates = {};
    //         for (const key in prevState) {
    //             updatedStates[key] = key === menuName ? !prevState[key] : false;
    //         }
    //         return updatedStates;
    //     });
    // }

    return (
        <>
            <h2>Shadow Billing</h2>
            {/* <ul className="nav nav-tabs">
                <li><button onClick={() => toggleTabs('tabBillHighlights')} className={`tab btn btn-toggle d-inline-flex align-items-center rounded ${tabStates.tabBillHighlights ? ' active' : ''}`}>Bill Highlights</button></li>
                <li><button onClick={() => toggleTabs('tabBillShadowing')} className={`tab btn btn-toggle d-inline-flex align-items-center rounded ${tabStates.tabBillShadowing ? ' active' : ''}`}>Shadow Billing</button></li>
            </ul>
            <div className="tab-content">
                <div className={`tab-pane${tabStates.tabBillHighlights ? ' active' : ''}`} id="BillHighlights">
                    <BillHighlightsContent />
                </div>
                <div className={`tab-pane${tabStates.tabBillShadowing ? ' active' : ''}`} id="BillShadowing">
                    <BillShadowingContent />
                </div>
            </div> */}
            <BillShadowingContent />
        </>
    );
}

export default CostOfServiceAnalysisBillManagerContent;
