import { Button } from '@mui/material';

export function SubmitButton( { filterHandler, loading, disabled, variant="small" } ) {
    return (
        <div className="col-lg-2 col-md-6 mb-lg-0 mb-1">
            <div className="customerBillingSearchButton  spark-search-tab-buttons">
                <label className="form-label invisible">Buttons</label>
                <div className="d-flex">
                    <Button variant={variant}
                        onClick={filterHandler}
                        disabled={disabled || loading}>
                            Get Results
                    </Button>
                </div>
            </div>
        </div>
    );
}
