
import React, { useState, useEffect, useMemo } from 'react';
import { GetQueryParams, GetModifiedBillingCycleList } from '../../../../util/Utility';
import { useLoaderData } from 'react-router-dom';
import { useAuthContext } from '../../../../store/AuthContext';
import BillShadowingDashboard from "./BillShadowingDashboard";
import {
    SingleSelector,
    SubmitButton,
    CustomerSelector
} from '../../../UI/UIComponents';

function BillShadowingContent() {
    const { clientConfig } = useAuthContext();

    const [customer, setCustomer] = useState('');
    const [yearMonth, setYearMonth] = useState('');
    const [filterParam, setFilterParam] = useState();
    const [loading, setLoading] = useState();
    const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);
    const queryParams = GetQueryParams();

    const { customerFilterData, billingCycleFilterData } = useLoaderData();

    const customerList = useMemo(() => customerFilterData.data.result.Items.map(item => {
        return {
            ...item,
            value: item.accountnumber
        }
    }), [customerFilterData]);

    const billingCycleList = useMemo(() => billingCycleFilterData.data.result.Items.map(item => {
        return {
            ...item,
            value: item.billing_period
        }
    }), [billingCycleFilterData]);

    const constructedBillingCycleList = GetModifiedBillingCycleList(billingCycleList, clientConfig);

    useEffect(() => {
        if(!queryFiredOnLoad) {
            const queryCustomer = queryParams.get("customer");
            const queryBillingCycle = queryParams.get("billingCycle");
            let runQuery = false;
            if (queryCustomer != null) {
                setCustomer(queryCustomer);
                runQuery = true;
            }

            if (queryBillingCycle != null) {
                setYearMonth(queryBillingCycle);
                runQuery = true;
            }

            if (runQuery) {
                setQueryFiredOnLoad(true);
                setLoading(true);
                setFilterParam({
                    'accountNumber': queryCustomer ?? customer,
                    'yearMonth': queryBillingCycle ?? yearMonth,
                });
            }
        }
    }, [customer, queryFiredOnLoad, queryParams, yearMonth]);

    const filterHandler = () => {
        setLoading(true);
        setFilterParam({
            'accountNumber': customer,
            'yearMonth': yearMonth,
        });
    }

    return (
        <div className="mainContent">
            <div className=" pl-32 pr-32">
                <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
                    <div className=" customerBillingForm spark-search-tab-card">
                        <form action="" method="post">
                            <div className="row mt-26">
                                <CustomerSelector
                                    label='Customer'
                                    onSelect={setCustomer}
                                    customers={customerList}
                                />

                                <div className="col-1" />

                                <SingleSelector
                                    value={yearMonth}
                                    setValue={setYearMonth}
                                    valueList={constructedBillingCycleList}
                                    label="Billing Cycle"
                                />

                                <div className="col-5" />

                                <SubmitButton
                                    filterHandler={filterHandler}
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <BillShadowingDashboard filterParam={filterParam} setLoading={setLoading} />
        </div>
    );
}

export default BillShadowingContent;