const defaultDays = 5;

const getTTL = (days) => {
  return days * 24 * 60 * 60 * 1000; // in milliseconds
};

const setStorageItem = (key, value, ttl = defaultDays) => {
  if (typeof localStorage === "undefined") {
    console.log("LocalStorage is undefined");
    return;
  }
  const cacheTTL = getTTL(ttl);
  const now = new Date();

  const item = {
    value,
    storageExpiry: now.getTime() + cacheTTL,
  };
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.error('[Storage:setStorage]', error);
    // Clear storage and start again
    if (error.name === 'QuotaExceededError') {
      localStorage.clear();
    }
  }
};

const getStorageItem = (key) => {
  if (typeof localStorage === "undefined") {
    console.log("LocalStorage is undefined");
    return null;
  }
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if ('storageExpiry' in item) {
    if (now.getTime() > item.storageExpiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  return item;
};

export {
  setStorageItem,
  getStorageItem
};
