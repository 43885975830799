import { MenuItem, Select, ListItemText, Checkbox, ListItemIcon } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

export function MultiSelector( { value, setValue, valueList, label, disabled, width } ) {
    const valueHandler = (event) => {
        const newValue = event.target.value;
        if (newValue[newValue.length - 1] === "all") {
            setValue(value.length === valueList.length ? [] : valueList.map((option) => option.value));
            return;
        }
        setValue(newValue);
    }
    const isAllSelected = valueList.length > 0 && value.length === valueList.length;
    const selector = (
        <Select
            disabled={disabled}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            displayEmpty
            value={value}
            onChange={valueHandler}
            renderValue={(selected) => {
                if (!selected || selected.length === 0) {
                    return <span>Select {label}</span>;
                }

                return selected.join(', ');
            }}
            // MenuProps={MenuProps}
        >

            <MenuItem value="all" key={`${label} all`}>
                <ListItemIcon>
                    <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                            value.length > 0 && value.length < valueList.length
                        }
                    />
                </ListItemIcon>
                <ListItemText primary="Select All" />
            </MenuItem>
            {valueList.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                    <Checkbox checked={value.indexOf(name.value) > -1} />
                    <ListItemText primary={name.label ?? name.value} />
                </MenuItem>
            ))}
        </Select>
    );
    if(width) {
        return (
            <div style={{ width: width }} className="mb-lg-0 mb-1">
                <label htmlFor="rateClass" className="form-label">{label}</label>

                <div className='row m-0'>
                    {selector}
                </div>
            </div>
        );
    }
    return (
        <div className="col-lg-2 col-md-6 mb-lg-0 mb-1">
            <label htmlFor="rateClass" className="form-label">{label}</label>

            <div className='row me-3'>
                {selector}
            </div>
        </div>
    );
}
