import { createTheme } from '@mui/material/styles';
import _ from 'lodash';

const baseTheme = {
  components: {
    MuiDiv: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: 'none',
        }
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginBottom: '25px',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          padding: '0.375rem 2.25rem 0.375rem 0.75rem',
          maxHeight: /* theme.height */48 * 4.5 + 8/* theme.paddingTop */,
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(["extra-small", "small", "medium", "large"].includes(ownerState.variant) && {
            padding: '7px 11px',
          'border-radius': '4px',
          'font-weight': 500,
          'font-size': '14px',
          'line-height': 1.5,
          'white-space': 'nowrap',
          border: `1px solid ${theme.color}`,
          transition: '.5s',
          })
        })
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          height: '35px',
          width: '100%',
          padding: '0.375rem 0.75rem',
        }
      }
    },
    MuiListItem: {
      variants: [
        {
          props: { variant: "sidebar-navigation-header" },
          style: {
            paddingLeft: '0px',
            marginLeft: '0px',
          }
        },
        {
          props: { variant: "sidebar-home" },
          style: {
            paddingLeft: '0px',
            marginLeft: '0px',
          }
        },
      ]
    },
    MuiList: {
      variants: [
        {
          props: { variant: "sidebar-navigation" },
          style: {
            marginLeft: '16px',
            marginRight: '16px',
          }
        },
        {
          props: { variant: "sidebar-subnavigation" },
          style: {
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '24px',
            marginLeft: '16px',
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "gridfin-header" },
          style: {
            textTransform: "lowercase",
          }
        },
        {
          props: { variant: "extra-small" },
          style: {
            height: '32px',
            width: '137px',
          }
        },
        {
          props: { variant: "small" },
          style: {
            height: '40px',
            width: '183px',
          }
        },
        {
          props: { variant: "medium" },
          style: {
            height: '48px',
            width: '170px',
          }
        },
        {
          props: { variant: "large" },
          style: {
            height: '56px',
            width: '222px',
          }
        },
      ]
    },
  }
};

const baseLightTheme = _.merge(_.cloneDeep(baseTheme), {
  palette: {
    mode: 'light',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#F7F7F7',
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F7F7F7',
        }
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          color: '#000000',
        }
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "&.Mui-selected": {
            ...((ownerState.variant === "sidebar-navigation-button" && {
              backgroundColor: '#E0E0E0',
            }) || (ownerState.variant === "sidebar-subnavigation-button" && {
              backgroundColor: '#E0E0E030',
            })),
          },
          ":hover": {
            backgroundColor: '#E0E0E050',
          },
          "&.Mui-selected:hover": {
            backgroundColor: '#E0E0E080',
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root:({ ownerState }) => ({
            ...(["extra-small", "small", "medium", "large"].includes(ownerState.variant) && {
            backgroundColor: '#0071B3',
            color: '#FFFFFF',
            border: 1,
            borderColor: '#FFFFFF',
            ":hover": {
              backgroundColor: '#00629B',
              color: '#FFFFFF',
              borderColor: '#FFFFFF',
            },
            "&.Mui-selected": {
              backgroundColor: '#0B4872',
              color: '#FFFFFF',
              borderColor: '#FFFFFF',
            },
            ":disabled": {
              backgroundColor: '#DFE0DF',
              color: '#606060',
              borderColor: '#606060',
            }
          }),
        }),
      },
      variants: [
        {
          props: { variant: "gridfin-header" },
          style: {
            backgroundColor: 'inherit',
            color: "#000",
          }
        },
      ]
    },
  },
});

const baseDarkTheme = _.merge(_.cloneDeep(baseTheme), {
  palette: {
    mode: 'dark',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#202020',
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#202020'
        }
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#272727',
        }
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "&.Mui-selected": {
            ...((ownerState.variant === "sidebar-navigation-button" && {
              backgroundColor: '#202020',
            }) || (ownerState.variant === "sidebar-subnavigation-button" && {
              backgroundColor: '#20202030',
            })),
          },
          ":hover": {
            backgroundColor: '#20202050',
          },
          "&.Mui-selected:hover": {
            backgroundColor: '#20202080',
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
            ...(["extra-small", "small", "medium", "large"].includes(ownerState.variant) && {
            backgroundColor: '#303030',
            color: '#FFFFFF',
            border: 1,
            borderColor: '#FFFFFF',
            ":hover": {
              backgroundColor: '#404040',
              color: '#FFFFFF',
              borderColor: '#FFFFFF',
            },
            "&.Mui-selected": {
              backgroundColor: '#707070',
              color: '#FFFFFF',
              borderColor: '#FFFFFF',
            },
            ":disabled": {
              backgroundColor: '#DFE0DF',
              color: '#606060',
              borderColor: '#606060',
            }
          }),
        }),
      },
      variants: [
        {
          props: { variant: "gridfin-header" },
          style: {
            backgroundColor: 'inherit',
            color: "#fff",
          }
        },
      ]
    },
  },
});

const defaultLightTheme = createTheme(baseLightTheme);
const defaultDarkTheme = createTheme(baseDarkTheme);
const honeywellTheme = createTheme(baseDarkTheme);

const gridFinThemes = {
  light: defaultLightTheme,
  dark: defaultDarkTheme,
  Honeywell: honeywellTheme,
};

export {
  gridFinThemes,
};
