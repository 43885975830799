import React, { useState } from 'react';
import {
  Tab,
  Box,
} from '@mui/material';
import {
  TabPanel,
  TabContext,
  TabList,
} from '@mui/lab';

import UserAdminContent from './UserAdmin/UserAdminContent';
import ConfigAdminContent from './ConfigAdmin/ConfigAdminContent';

const AdminContent = () => {
  const [tab, setTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Users" value="1" />
            <Tab label="Config" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><UserAdminContent /></TabPanel>
        <TabPanel value="2"><ConfigAdminContent /></TabPanel>
      </TabContext>
    </Box>
  );
};

export default AdminContent;
