import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { debounce } from 'lodash';

const SearchSelector = ({
  label,
  loading,
  onSearch,
  onSelect,
  startingValue,
  options
}) => {
  const autocompleteRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(startingValue);
  const [loadWithStartingValue, setLoadWithStartingValue] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);


  const debouncedSearch = useMemo(() =>
      debounce((val) => {
        if (val && val !== '' && val.length > 2)
        {
          setSearchTerm(val);
          onSearch(val)
        }
      }, 750),
    [onSearch]
  );

  useMemo(() => {
    if(!loadWithStartingValue && startingValue && options.find((option) => option.value === startingValue) !== undefined && selectedValue === null){
      setLoadWithStartingValue(true);
      setSearchTerm(startingValue);
      setSelectedValue(options.find((option) => option.value === startingValue));
    }
  }, [loadWithStartingValue, options, selectedValue, startingValue])

  const handleInputChange = useCallback((value) => {
    debouncedSearch(value)
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchTerm && searchTerm !== '' && options.length > 0) {
      const matchingItem = options.find(
        (item) => item.accountnumber.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
      if (matchingItem && autocompleteRef.current) {
        autocompleteRef.current.scrollIntoView(matchingItem);
      }
    }
  }, [options, searchTerm]);

  return (
    <div className="col-lg-2 col-md-6 mb-lg-0 mb-1">
      <label htmlFor={`${label}-search`} className="form-label">{label}</label>
      <div className='row me-3'>
        <Autocomplete
          id={`${label}-search`}
          ref={autocompleteRef}
          value={selectedValue}
          onChange={(_, newValue) => {
            setSelectedValue(newValue);
            onSelect(newValue ?? {value: ''});
          }}
          options={options}
          getOptionLabel={(option) => option.value}
          style={{ paddingLeft: 0 }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchTerm}
              onChange={(e) => handleInputChange(e.target.value)}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

SearchSelector.defaultProps = {
  loading: false,
};

SearchSelector.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.any,
    value: PropTypes.string.isRequired
  }),
  startingValue: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    value: PropTypes.string.isRequired
  })),
};

export { SearchSelector };
