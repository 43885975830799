import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../util/APIDataLoader';

function AnalysisDashboard( { filterParam, setLoading }) {

    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('supplierDetail', ConstructFilterParams(filterParam), setLoading, "supplierAnalysisContainer");
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="supplierAnalysisContainer"></div>
    );
}

export default AnalysisDashboard;
