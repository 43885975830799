

import ExecutiveSummaryContent from "../components/SystemOverview/ExecutiveSummaryContent";
// import { APIDataLoader } from "../util/APIDataLoader";

function Home() {
    return (
        <ExecutiveSummaryContent />
    )
}

export default Home;

export async function loader() {
    return {};
    // const promises = await Promise.all([
    //     APIDataLoader('supplierSelector'),
    //     APIDataLoader('billingSelector')
    // ])

    // return {
    //     supplierFilterData: promises[0],
    //     billingCycleFilterData: promises[1]
    // };
}