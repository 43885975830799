import { useEffect } from 'react';
import { APIDashboardDataLoader, ConstructFilterParams } from '../../../util/APIDataLoader';

function CustomerOverviewDashboard( { filterParam, setLoading }) {
    useEffect(() => {
        const getQuickSightDashboardEmbedURL = async () => {
            if(filterParam !== undefined) {
                APIDashboardDataLoader('customerOverview', ConstructFilterParams(filterParam), setLoading, 'customerOverviewDashboardContainer');
            }
        };
        getQuickSightDashboardEmbedURL();
    }, [filterParam, setLoading]);

    return (
        <div id="customerOverviewDashboardContainer"></div>
    );
}

export default CustomerOverviewDashboard;
