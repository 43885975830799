import React from 'react';

import PreferencesContent from '../components/Preferences/PreferencesContent';

const Preferences = () => {
  return (
    <PreferencesContent />
  );
};

export default Preferences;

export const loader = async () => {
  return {};
};
