
import CustomerAnalysisContent from "../../components/RevenueFromService/CustomerAnalysis/CustomerAnalysisContent";
import { APIDataLoader } from "../../util/APIDataLoader";

function CustomerAnalysis() {
    return (
        <CustomerAnalysisContent />
    )
}

export default CustomerAnalysis;

export async function loader() {
    const promises = await Promise.all([
        APIDataLoader('customerSelector')
    ]);

    return {
        customerFilterData: promises[0],
    };
}
