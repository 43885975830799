import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function GetQueryParams() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export function GetModifiedBillingCycleList(billingCycleList, clientConfig) {

    let constructedBillingCycleList = [];
    let earliestValue, startMonth, startYear;
    if (clientConfig?.filters?.yearSpan){
        startMonth = clientConfig.filters.startMonth;
        startYear = clientConfig.filters.startYear;
        earliestValue = dayjs(`${startMonth} ${startYear}`);
    } else if (clientConfig?.filters?.yearSpan){
        earliestValue = dayjs().subtract(clientConfig.filters.yearSpan, 'year');
    } else if (billingCycleList.length > 0) {
        earliestValue = dayjs(billingCycleList[billingCycleList.length-1].value);
    } else {
        constructedBillingCycleList.push({value: 'Missing config.', label: 'Missing config, contact your Admin.'});
    }
    let dateIteration = dayjs().subtract(1, 'day');
    let ongoing = true;

    while(earliestValue.isBefore(dateIteration)) {
        const formattedDate = dateIteration.format("MMMM YYYY");
        dateIteration = dateIteration.subtract(1, 'month');
        const found = billingCycleList.findIndex((cycle)=> cycle.value === formattedDate) >= 0;
        constructedBillingCycleList.push({ billing_period: formattedDate, value: formattedDate, label: `${formattedDate}${!found ? '*' : ''}${ongoing ? ' (ongoing)' : ''}`});
        ongoing = false;
    };

    return constructedBillingCycleList;
}