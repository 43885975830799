
function NotAuthorizedPage() {
    return (
        <div className="mainContent">
            <div className=" pl-32 pr-32">
                <div className="TitleSearch mt-26 mb-26 d-flex align-items-center justify-content-between">
                    <h2 className="mainContentTitle">
                        Not Authorized
                    </h2>
                </div>

                <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
                    If you have come to this page and you feel this has been in error, please contact your administrator to gain access.
                </div>

            </div>
        </div>
    )
}

export default NotAuthorizedPage;