import dayjs from 'dayjs';
import React, { useMemo, useState, useEffect } from 'react';
import AnalysisDashboard from "./SupplierAnalysisDashboard";
import { useLoaderData } from 'react-router-dom';
import { GetQueryParams } from '../../../util/Utility';
import { useAuthContext } from '../../../store/AuthContext';

import { SingleSelector, CalendarSelector, SubmitButton } from '../../UI/UIComponents';

function CostOfServiceSupplierDetailContent() {
    const { clientConfig } = useAuthContext();

    const [supplier, setSupplier] = useState('');
    const [startDate, setStartDate] = useState(dayjs().subtract(12, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [filterParam, setFilterParam] = useState();
    const [loading, setLoading] = useState();
    const [queryFiredOnLoad, setQueryFiredOnLoad] = useState(false);
    const queryParams = GetQueryParams();

    let minDate;
    if (clientConfig?.filters?.yearSpan){
        minDate = dayjs().subtract(clientConfig.filters.yearSpan, 'year');
    }

    const [ supplierFilterData ] = useLoaderData();
    const supplierList = useMemo(() => supplierFilterData.data.result.Items.map(item => {
        return {
            ...item,
            value: item.supplier_name
        }
    }), [supplierFilterData.data.result.Items]);

    useEffect(() => {
        if(!queryFiredOnLoad) {
            const querySupplier = queryParams.get("supplier");
            const queryStartDate = queryParams.get("startDate");
            const queryEndDate = queryParams.get("endDate");
            let newSupplier, newStartDate, newEndDate;
            // let runQuery = false;
            if (querySupplier != null) {
                const inputSupplier = supplierList.filter((item) => item.supplier_id === querySupplier);
                if (inputSupplier.length === 1) {
                    newSupplier = inputSupplier[0].value;
                    setSupplier(newSupplier);
                    // runQuery = true;
                }
            } else {
                setSupplier(supplierList[0].value);
            }

            if (queryStartDate != null) {
                newStartDate = dayjs(queryStartDate);
                setStartDate(newStartDate);
                // runQuery = true;
            }

            if (queryEndDate != null) {
                newEndDate = dayjs(queryEndDate);
                setEndDate(newEndDate);
                // runQuery = true;
            }

            // if (runQuery) {
                setQueryFiredOnLoad(true);
                setLoading(true);
                setFilterParam({
                    'supplier': newSupplier ?? supplier,
                    'startDate': newStartDate ?? startDate,
                    'endDate': newEndDate ?? endDate,
                });
            // }
        }
    }, [supplier, supplierList, queryParams, queryFiredOnLoad, startDate, endDate]);

    const filterHandler = () => {
        setLoading(false);
        setFilterParam({
            'supplier': supplier,
            'startDate': startDate,
            'endDate': endDate,
        });
    }

    return (
        <>
            <div className="mainContent">
                <div className=" pl-32 pr-32">
                    <div className="TitleSearch mt-26 mb-26 d-flex align-items-center justify-content-between">
                        <h2 className="mainContentTitle">
                            Supplier Analysis
                        </h2>
                    </div>

                    <div className="byRatesFilter mainContentFilter serviceAnalysisCostOverTime">
                        <div className=" customerBillingForm spark-search-tab-card">
                            <form action="" method="post">
                                <div className="row">
                                    <SingleSelector
                                        value={supplier}
                                        setValue={setSupplier}
                                        valueList={supplierList}
                                        label="Supplier"
                                    />

                                    <div className="col-1" />

                                    <CalendarSelector
                                        dateValue={startDate}
                                        setDateValue={setStartDate}
                                        minDate={minDate}
                                        disableFuture={true}
                                        views={["year", "month"]}
                                        label="Start Date"
                                    />

                                    <CalendarSelector
                                        dateValue={endDate}
                                        setDateValue={setEndDate}
                                        minDate={startDate}
                                        disableFuture={true}
                                        views={["year", "month"]}
                                        label="End Date"
                                    />

                                    <div className="col-3" />

                                    <SubmitButton
                                        filterHandler={filterHandler}
                                        loading={loading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <AnalysisDashboard filterParam={filterParam} setLoading={setLoading} />
            </div>
        </>
    );
}

export default CostOfServiceSupplierDetailContent;
